<template>
   
<!--EvaluationEconomic start--> 
<div class="box-onglets detail margin15">
    <div class="content-onglets table">
        <template v-for="(items, key) in data" :key="'type_of_economic_analysis_'+key">
            <table class="comparateur2" >
                <tbody>
                    <tr>
                        <td class="bg-orange" width="27.5%"><span> {{$t(prefix_+'type_of_economic_analysis')}} : <br> 
                            <strong>{{$t(items['type_of_economic_analysis'])}}</strong></span>
                        </td>
                        <td class="mt-0 p-0 text-left">
                            <table class="comparateur2 tb-max " style="margin-bottom:0px;">

                                <tr>
                                    <td class="text-left no-border-left-right border-top-gray">
                                        {{$t('ceesp_economic_modeling_type_of_model')}} :
                                        <span class="text-orange-check" v-if="$i18n.locale == 'fr'|| $route.params.agency != 'ceesp' ">{{items['economic_modeling_type_of_model'] ? items['economic_modeling_type_of_model'] : '-'}}</span>                                        
                                        <span class="text-orange-check" v-else>{{items['economic_modeling_type_of_model_en'] ? items['economic_modeling_type_of_model_en'] : '-'}}</span>                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left no-border-left-right">
                                        {{$t('ceesp_critere_resultat')}} :
                                        <span class="text-orange-check" v-if="$i18n.locale == 'fr' || $route.params.agency != 'ceesp' ">{{items['economic_modeling_critere_resultat'] ? items['economic_modeling_critere_resultat'] : '-'}}</span>
                                        <span class="text-orange-check" v-else>{{items['economic_modeling_critere_resultat_en'] ? items['economic_modeling_critere_resultat_en'] : '-'}}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-left no-border-left-right">
                                        {{$t('economic_modeling_cycle_length')}} :
                                        <span class="text-orange-check" v-if="$i18n.locale == 'fr' || $route.params.agency != 'ceesp' ">{{items['economic_modeling_cycle_length'] ? items['economic_modeling_cycle_length'] : '-'}}</span>
                                        <span class="text-orange-check" v-else>{{items['economic_modeling_cycle_length_en'] ? items['economic_modeling_cycle_length_en'] : '-'}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left no-border-left-right">
                                        {{$t('economic_modeling_perspective')}} :
                                        <span class="text-orange-check" v-if="$i18n.locale == 'fr' || $route.params.agency != 'ceesp'">{{items['economic_modeling_perspective'] ? items['economic_modeling_perspective'] : '-'}}</span>
                                        <span class="text-orange-check" v-else>{{items['economic_modeling_perspective_en'] ? items['economic_modeling_perspective_en'] : '-'}}</span>
                                    </td>
                                </tr>   
                                <tr>
                                    <td class="text-left no-border-left-right">
                                        {{$t('economic_modeling_time_horizon')}} :
                                        <span class="text-orange-check" v-if="$i18n.locale == 'fr' || $route.params.agency != 'ceesp'">{{items['economic_modeling_time_horizon'] ? items['economic_modeling_time_horizon'] : '-'}}</span>
                                        <span class="text-orange-check" v-else>{{items['economic_modeling_time_horizon_en'] ? items['economic_modeling_time_horizon_en'] : '-'}}</span>
                                    </td>
                                </tr>        
                                <tr>
                                    <td class="text-left no-border-left-right no-border-bottom">
                                        {{$t(prefix_+'economic_modeling_discounting')}}:
                                        <span class="text-orange-check" v-if="$i18n.locale == 'fr' || $route.params.agency != 'ceesp' ">{{items['economic_modeling_percentage_discounter'] ? items['economic_modeling_percentage_discounter'] : '-'}}</span>
                                        <span class="text-orange-check" v-else>{{items['economic_modeling_percentage_discounter_en'] ? items['economic_modeling_percentage_discounter_en'] : '-'}}</span>
                                    </td>
                                </tr>          
                            </table>
                        </td> 
                        <td style="text-align: center; width: 90px;" class="no-border-left">
                            <a @click="collapse_toggle(key)">
                                <em class="icons icon-plus-m"></em>
                            </a>
                        </td>

                    </tr>
                </tbody>
            </table>

            <div :key="'collapse_'+key" v-if="collapse_show[key] == 1" :id="'collapse-'+key">
                <table class="comparateur2 text-left border-top-gray">
                    <tbody  v-if="items['parameters_population_analyzed'] || items['parameters_population_analyzed_en'] ||
                                    items['parameters_dosage'] || items['parameters_dosage_en'] ||
                                    items['parameters_effectiveness'] || items['parameters_effectiveness_en'] ||
                                    items['parameters_cost_types'] || items['parameters_cost_types_en']">
                        <tr class="content-orange" v-if="(($i18n.locale == 'fr' || $route.params.agency != 'ceesp') && items['parameters_population_analyzed']) || 
                                    ($i18n.locale != 'fr' && items['parameters_population_analyzed_en'])">
                            <td style="width:35%; border-top: 0px;"><strong>{{$t(prefix_+'parameters_population_analyzed')}}</strong></td>
                            <td style="border-top: 0px;" v-if="$i18n.locale == 'fr' || $route.params.agency != 'ceesp'">{{items['parameters_population_analyzed']}}</td>
                            <td style="border-top: 0px;" v-else>{{items['parameters_population_analyzed_en']}}</td>
                        </tr>
                    
                        <tr class="content-orange" v-if="items['evaluation_economic_clinical_trial'].length > 0">
                            <td><strong>{{ $t(prefix_+'economic_evaluation_clinical_trials')}} </strong></td>
                            <td>
                                <template v-for="(value, key) in items['evaluation_economic_clinical_trial']" :key="'clinical_trial_name'+key">
                                        <p v-html="value['clinical_trial_name']"></p>
                                </template>
                                
                            </td>
                        </tr>
                    
                        <tr class="content-orange" v-if="(($i18n.locale == 'fr' || $route.params.agency != 'ceesp') && items['parameters_dosage']) || 
                                    ($i18n.locale != 'fr' && items['parameters_dosage_en'])">
                            <td><strong>{{$t(prefix_+'parameters_dosage')}}</strong></td>
                            <td v-if="$i18n.locale == 'fr' || $route.params.agency != 'ceesp' ">{{items['parameters_dosage']}}</td>
                            <td v-else>{{items['parameters_dosage_en']}}</td>
                        </tr>
                    
                        <tr class="content-orange" v-if="(($i18n.locale == 'fr' || $route.params.agency != 'ceesp') && items['parameters_effectiveness']) || 
                                    ($i18n.locale != 'fr' && items['parameters_effectiveness_en'])">
                            <td><strong>{{$t(prefix_+'parameters_effectiveness')}}</strong></td>
                            <td v-if="$i18n.locale == 'fr' || $route.params.agency != 'ceesp' ">{{items['parameters_effectiveness']}}</td>
                            <td v-else>{{items['parameters_effectiveness_en']}}</td>
                        </tr>
                    
                        <tr class="content-orange" v-if="(($i18n.locale == 'fr' || $route.params.agency != 'ceesp') && items['parameters_utility']) || 
                                    ($i18n.locale != 'fr' && items['parameters_utility_en'])">
                            <td><strong>{{$t('parameters_utility')}}</strong></td>
                            <td v-if="$i18n.locale == 'fr' || $route.params.agency != 'ceesp' ">{{items['parameters_utility']}}</td>
                            <td v-else>{{items['parameters_utility_en']}}</td>
                        </tr>  
                    
                        <tr class="content-orange" v-if="(($i18n.locale == 'fr' || $route.params.agency != 'ceesp') && items['parameters_cost_types']) || 
                                    ($i18n.locale != 'fr' && items['parameters_cost_types_en'])">
                            <td><strong>{{$t(prefix_+'parameters_cost_types')}}</strong></td>
                            <td v-if="$i18n.locale == 'fr' || $route.params.agency != 'ceesp' ">{{items['parameters_cost_types']}}</td>
                            <td v-else>{{items['parameters_cost_types_en']}}</td>
                        </tr>  
                    
                    </tbody>
                        
                </table>
                <div class="box-toggle" :key="'economic_analysis_head'+key" :id="'economic_analysis-'+key" v-if="items.evaluation_economic_economic_analysis.length > 0">
                <a class="title title-tb" style="cursor:pointer" @click="economic_analysis_show[key] = !economic_analysis_show[key]">
                    {{$t(prefix_+'economic_analysis_head')}} <em :class="economic_analysis_show[key] ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em> 
                </a>

                <div v-if="economic_analysis_show[key]">             
                    <table class="comparateur2 border-top-gray">
                        <tbody>
                            <tr style="text-align: center !important;">
                                <th width="15%" v-if="not_empty_row['source_of_analysis']">
                                    {{$t(prefix_+'source_of_analysis')}}
                                </th>
                                <th width="15%" v-if="not_empty_row['patient_population']">
                                    {{$t(prefix_+'patient_population')}}
                                </th>
                                <th width="15%" v-if="not_empty_row['comparator']">
                                    {{$t(prefix_+'comparator')}}
                                </th>
                                <th width="15%" v-if="not_empty_row['icer_gbp']">
                                    {{$t(prefix_+'icer_gbp')+prefix_flag}} 
                                </th>
                                <th width="15%" v-if="not_empty_row['icer_range_gbp']">
                                    {{$t(prefix_+'icer_range_gbp')+prefix_flag}}
                                </th>
                                <th width="25%" v-if="not_empty_row['icur_gbp']">
                                    {{$t(prefix_+'icur_gbp')+prefix_icur_flag}}
                                </th>
                                <th width="25%" v-if="not_empty_row['icur_range_gbp']">
                                    {{$t(prefix_+'icur_range_gbp')+prefix_icur_flag}}
                                </th>
                                <th width="15%" v-if="not_empty_row['cost_savings_gbp']">
                                    {{$t('cost_savings_gbp')+prefix_flag}}
                                </th>
                                <th width="15%" v-if="not_empty_row['total_cost_gbp']">
                                    {{$t('total_cost_gbp')+prefix_flag}}
                                </th>
                                <th width="15%" v-if="not_empty_row['net_monetary_benefit_gbp']">
                                    {{$t('net_monetary_benefit_gbp')+prefix_flag}}
                                </th>
                                <th width="15%" v-if="not_empty_row['net_monetary_loss_gbp']">
                                    {{$t('net_monetary_loss_gbp')+prefix_flag}}
                                </th>
                                <th width="25%" colspan="2" v-if="not_empty_row['smc_comment']">
                                    {{$t(prefix_comment+'comment')}}
                                </th>
                            </tr> 

                            <tr v-for="(item_economic_analysis, key2) in items.evaluation_economic_economic_analysis" :key="key2"> 
                                <td v-if="not_empty_row['source_of_analysis']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'source_of_analysis_en' : 'source_of_analysis'])}}
                                </td>
                                <td v-if="not_empty_row['patient_population']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'patient_population_en' : 'patient_population'])}}
                                </td>
                                <td v-if="not_empty_row['comparator']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'comparator_en' : 'comparator'])}}
                                </td>
                                <td v-if="not_empty_row['icer_gbp']"> 
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icer_gbp_en' : 'icer_gbp'])}}
                                </td>
                                <td v-if="not_empty_row['icer_range_gbp']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icer_range_gbp_en' : 'icer_range_gbp'])}}
                                </td>
                                <td v-if="not_empty_row['icur_gbp']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icur_gbp_en' : 'icur_gbp'])}}
                                </td>
                                <td v-if="not_empty_row['icur_range_gbp']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icur_range_gbp_en' : 'icur_range_gbp'])}}
                                </td>
                                <td v-if="not_empty_row['cost_savings_gbp']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'cost_savings_gbp_en' : 'cost_savings_gbp'])}}
                                </td>
                                <td v-if="not_empty_row['total_cost_gbp']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'total_cost_gbp_en' : 'total_cost_gbp'])}}
                                </td>
                                <td v-if="not_empty_row['net_monetary_benefit_gbp']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'net_monetary_benefit_gbp_en' : 'net_monetary_benefit_gbp'])}}
                                </td>
                                <td v-if="not_empty_row['net_monetary_loss_gbp']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'net_monetary_loss_gbp_en' : 'net_monetary_loss_gbp'])}}
                                </td>
                                <td v-if="not_empty_row['smc_comment']">
                                    {{check_empty(item_economic_analysis[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'smc_comment_en' : 'smc_comment'])}}
                                </td>
                            </tr> 
                        </tbody>
                    </table>

                </div>    
            </div><!---box-toggle--->   
        
            <div class="box-toggle" :key="'dsa'+'_'+key" v-if="economic_sensitivity_analysis_dsa.length > 0 && items['type_of_economic_analysis'] != 'cma'" :id="'economic_sensitivity_analysis_dsa-'+key">
                <a class="title title-tb" @click="toggle_sensitivity_analysis('dsa')" style="cursor:pointer;">
                    {{$t('sensitivity_analysis_head')}} - {{$t(prefix_+'sensitivity_analysis')}} : <span >{{$t(prefix_+'dsa')}}</span>                            
                    <em :class="sensitivity_analysis_show['dsa'] ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                </a>
                <div v-if="sensitivity_analysis_show['dsa']">
                    <table class="comparateur2 border-top-gray">
                        <tbody>
                            <tr style="text-align: center !important;">
                                <th width="20%" v-if="not_empty_row_dsa['source_of_analysis']">
                                    {{$t("ceesp_source_of_analysis")}}
                                </th>
                                <th width="20%" v-if="not_empty_row_dsa['patient_population']">
                                    {{$t(prefix_+'patient_population')}}
                                </th>
                                <th width="20%" v-if="not_empty_row_dsa['comparator']">
                                    {{$t(prefix_+'comparator')}}
                                </th>
                                <th width="20%" v-if="not_empty_row_dsa['parameter']">
                                    {{$t(prefix_+'parameter_dsa')}}
                                </th>
                                <th width="20%" v-if="not_empty_row_dsa['icer'] && items['type_of_economic_analysis'] == 'cea'">
                                    {{$t(prefix_+'icer_2')}}
                                </th>
                                <th width="20%" v-if="not_empty_row_dsa['icur'] && items['type_of_economic_analysis'] == 'cua'">
                                    {{$t(prefix_+'icur')}}
                                </th>
                                <th width="30%" v-if="not_empty_row_dsa['smc_comment']">
                                    {{$t(prefix_comment+'comment')}}
                                </th>
                            </tr>
                            <tr v-for="(item_sensitivity_analysis_dsa, key) in economic_sensitivity_analysis_dsa" :key="key">
                                <td v-if="not_empty_row_dsa['source_of_analysis']">
                                    {{check_empty(item_sensitivity_analysis_dsa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'source_of_analysis_en' : 'source_of_analysis'])}}
                                </td>
                                <td v-if="not_empty_row_dsa['patient_population']">
                                    {{check_empty(item_sensitivity_analysis_dsa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'patient_population_en' : 'patient_population'])}}
                                </td>
                                <td v-if="not_empty_row_dsa['comparator']">
                                    {{check_empty(item_sensitivity_analysis_dsa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'comparator_en' : 'comparator'])}}
                                </td>
                                <td v-if="not_empty_row_dsa['parameter']">
                                    {{check_empty(item_sensitivity_analysis_dsa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'parameter_en' : 'parameter'])}}
                                </td>
                                <td v-if="not_empty_row_dsa['icer'] && items['type_of_economic_analysis'] == 'cea'">
                                    {{check_empty(item_sensitivity_analysis_dsa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icer_en' : 'icer'])}}
                                </td>
                                <td v-if="not_empty_row_dsa['icur'] && items['type_of_economic_analysis'] == 'cua'">
                                    {{check_empty(item_sensitivity_analysis_dsa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icur_en' : 'icur'])}}
                                </td>
                                <td v-if="not_empty_row_dsa['smc_comment']">
                                    {{check_empty(item_sensitivity_analysis_dsa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'smc_comment_en' : 'smc_comment'])}}
                                </td>
                            </tr>  
                        </tbody>
                    </table> 
                </div><!--table-responsive--> 
            </div><!---box-toggle-->

            <div class="box-toggle" :key="'psa_'+key" v-if="economic_sensitivity_analysis_psa.length > 0 && items['type_of_economic_analysis'] != 'cma'" :id="'economic_sensitivity_analysis_psa-'+key">
                <a class="title title-tb" @click="toggle_sensitivity_analysis('psa')" style="cursor:pointer;">                       
                    {{$t('sensitivity_analysis_head')}} - {{$t(prefix_+'sensitivity_analysis')}} : <span class="text-orange">{{$t(prefix_+'psa')}}</span>                            
                    <em :class="sensitivity_analysis_show['psa'] ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                </a> 
                <div v-if="sensitivity_analysis_show['psa']">
                    <table class="comparateur2 border-top-gray">
                        <tbody>
                            <tr style="text-align: center !important;">
                                <th width="20%" v-if="not_empty_row_psa['source_of_analysis']">
                                    {{$t("ceesp_source_of_analysis")}}
                                </th>
                                <th width="20%" v-if="not_empty_row_psa['patient_population']">
                                    {{$t(prefix_+'patient_population')}}
                                </th>
                                <th width="20%" v-if="not_empty_row_psa['comparator']">
                                    {{$t(prefix_+'comparator')}}
                                </th>
                                <th width="20%" v-if="not_empty_row_psa['method']">
                                    <template v-if="items['type_of_economic_analysis'] == 'cua'">
                                        {{$t(prefix_+'method_cost_utility_analysis')}}
                                    </template>
                                    <template v-else>
                                        {{$t(prefix_+'method')}}
                                    </template>
                                </th>
                                <th width="20%" v-if="not_empty_row_psa['icer_probability'] && items['type_of_economic_analysis'] == 'cea'">
                                    {{$t(prefix_+'icer_probability')}}
                                </th> 
                                <th width="20%" v-if="not_empty_row_psa['icur_probability'] && items['type_of_economic_analysis'] == 'cua'">
                                    {{$t(prefix_+'icur_probability')}}
                                </th>
                                <th width="30%" v-if="not_empty_row_psa['smc_comment']">
                                    {{$t(prefix_comment+'comment')}}
                                </th>
                            </tr>
                            <tr v-for="(item_sensitivity_analysis_psa, key) in economic_sensitivity_analysis_psa" :key="key">
                                <td v-if="not_empty_row_psa['source_of_analysis']">
                                    {{check_empty(item_sensitivity_analysis_psa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'source_of_analysis_en' : 'source_of_analysis'])}}
                                </td>
                                <td v-if="not_empty_row_psa['patient_population']">
                                    {{check_empty(item_sensitivity_analysis_psa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'patient_population_en' : 'patient_population'])}}
                                </td>
                                <td v-if="not_empty_row_psa['comparator']">
                                    {{check_empty(item_sensitivity_analysis_psa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'comparator_en' : 'comparator'])}}
                                </td>
                                <td v-if="not_empty_row_psa['method']">
                                    {{check_empty(item_sensitivity_analysis_psa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'method_en' : 'method'])}}
                                </td>
                                <td v-if="not_empty_row_psa['icer_probability']">
                                    {{check_empty(item_sensitivity_analysis_psa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icer_probability_en' : 'icer_probability'])}}
                                </td>
                                <td v-if="not_empty_row_psa['icur_probability']">
                                    {{check_empty(item_sensitivity_analysis_psa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icur_probability_en' : 'icur_probability'])}}
                                </td>
                                <td v-if="not_empty_row_psa['smc_comment']">
                                    {{check_empty(item_sensitivity_analysis_psa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'smc_comment_en' : 'smc_comment'])}}
                                </td>
                            </tr>  
                        </tbody>
                    </table> 
                </div>    
            </div><!---box-toggle-->

            <div class="box-toggle" :key="'sa_'+key" v-if="economic_sensitivity_analysis_sa.length > 0 && items['type_of_economic_analysis'] != 'cma'" :id="'economic_sensitivity_analysis_sa-'+key">
                    <a class="title title-tb" @click="toggle_sensitivity_analysis('sa')" style="cursor:pointer;" >
                        {{$t('sensitivity_analysis_head')}} - {{$t(prefix_+'sensitivity_analysis')}} : <span class="text-orange-check">{{$t(prefix_+'sa')}}</span>                            
                        <em :class="sensitivity_analysis_show['sa'] ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                    </a> 
                <div v-if="sensitivity_analysis_show['sa']">
                    <table class="comparateur2 border-top-gray">
                        <tbody>
                            <tr style="text-align: center !important;">
                                <th width="20%" v-if="not_empty_row_sa['source_of_analysis']">
                                    {{$t("ceesp_source_of_analysis")}}
                                </th>
                                <th width="20%" v-if="not_empty_row_sa['patient_population']">
                                    {{$t(prefix_+'patient_population')}}
                                </th>
                                <th width="20%" v-if="not_empty_row_sa['comparator']">
                                    {{$t(prefix_+'comparator')}}
                                </th>
                                <th width="20%" v-if="not_empty_row_sa['scenario']">
                                    {{$t(prefix_+'Scenario')}}
                                </th>
                                <th width="20%" v-if="not_empty_row_sa['icur']">
                                    {{$t(prefix_+'icur')}}
                                </th>
                                <th width="30%" v-if="not_empty_row_sa['smc_comment']">
                                    {{$t(prefix_comment+'comment')}}
                                </th>
                            </tr>
                            <tr v-for="(item_sensitivity_analysis_sa, key) in economic_sensitivity_analysis_sa" :key="key">
                                <td v-if="not_empty_row_sa['source_of_analysis']">
                                    {{check_empty(item_sensitivity_analysis_sa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'source_of_analysis_en' : 'source_of_analysis'])}}
                                </td>
                                <td v-if="not_empty_row_sa['patient_population']">
                                    {{check_empty(item_sensitivity_analysis_sa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'patient_population_en' : 'patient_population'])}}
                                </td>
                                <td v-if="not_empty_row_sa['comparator']">
                                    {{check_empty(item_sensitivity_analysis_sa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'comparator_en' : 'comparator'])}}
                                </td>
                                <td v-if="not_empty_row_sa['scenario']">
                                    {{check_empty(item_sensitivity_analysis_sa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'scenario_en' : 'scenario'])}}
                                </td>
                                <td v-if="not_empty_row_sa['icur']">
                                    {{check_empty(item_sensitivity_analysis_sa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icur_en' : 'icur'])}}
                                </td>
                                <td v-if="not_empty_row_sa['smc_comment']">
                                    {{check_empty(item_sensitivity_analysis_sa[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'smc_comment_en' : 'smc_comment'])}}
                                </td>
                            </tr>  
                        </tbody>
                    </table> 
                </div><!--table-responsive--> 
            </div><!---box-toggle-->

            <div class="box-toggle" :key="'def_'+key" v-if="economic_sensitivity_analysis_def.length > 0" :id="'economic_sensitivity_analysis_def'+key">
                <a class="title title-tb" @click="toggle_sensitivity_analysis('def')" style="cursor:pointer;">
                    {{$t('sensitivity_analysis_head')}} - {{$t(prefix_+'sensitivity_analysis')}} : <span class="text-orange-check">-</span>                            
                    <em :class="sensitivity_analysis_show['def'] ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                </a> 

                <div v-if="sensitivity_analysis_show['def']">
                    <table class="comparateur2 border-top-gray">
                        <tbody>
                            <tr style="text-align: center !important;">
                                <th width="20%"  v-if="not_empty_row_def['source_of_analysis']">
                                    {{$t("ceesp_source_of_analysis")}}
                                </th>
                                <th width="20%"  v-if="not_empty_row_def['patient_population']">
                                    {{$t(prefix_+'patient_population')}}
                                </th>
                                <th width="20%"  v-if="not_empty_row_def['comparator']">
                                    {{$t(prefix_+'comparator')}}
                                </th>

                                <template v-if="items['type_of_economic_analysis'] == 'cea'">
                                <th width="20%"  v-if="not_empty_row_def['method']">
                                    {{$t('method')}}
                                </th>
                                <th width="20%"  v-if="not_empty_row_def['icer']">
                                    {{$t('icer')}}
                                </th>
                                <th width="20%"  v-if="not_empty_row_def['icer_probability']">
                                    {{$t('icer_probability')}}
                                </th>
                                </template>

                                <template v-if="items['type_of_economic_analysis'] == 'cua'">
                                <th width="20%"  v-if="not_empty_row_def['parameter']">
                                    {{$t('parameter')}}
                                </th>
                                <th width="20%"  v-if="not_empty_row_def['icur']">
                                    {{$t('icur')}}
                                </th>
                                <th width="20%"  v-if="not_empty_row_def['icur_probability']">
                                    {{$t('icur_probability')}}
                                </th>
                                </template>

                                <template v-if="items['type_of_economic_analysis'] == 'cma'">
                                <th width="20%"  v-if="not_empty_row_def['parameter']">
                                    {{$t('parameter')}}
                                </th>
                                </template>

                                <th width="20%"  v-if="not_empty_row_def['results']">
                                    {{$t('results')}}
                                </th>
                                <th width="30%"  v-if="not_empty_row_def['smc_comment']">
                                    {{$t('comment')}}
                                </th>
                            </tr>
                            <tr v-for="(item_sensitivity_analysis_def, key) in economic_sensitivity_analysis_def" :key="key">
                                <td v-if="not_empty_row_def['source_of_analysis']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'source_of_analysis_en' : 'source_of_analysis'])}}
                                </td>
                                <td v-if="not_empty_row_def['patient_population']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'patient_population_en' : 'patient_population'])}}
                                </td> 
                                <td v-if="not_empty_row_def['comparator']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'comparator_en' : 'comparator'])}}
                                </td>
                                <td v-if="not_empty_row_def['method']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'method_en' : 'method'])}}
                                </td>
                                <td v-if="not_empty_row_def['icer']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icer_en' : 'icer'])}}
                                </td>
                                <td v-if="not_empty_row_def['icer_probability']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icer_probability_en' : 'icer_probability'])}}
                                </td>
                                <td v-if="not_empty_row_def['parameter']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'parameter_en' : 'parameter'])}}
                                </td>
                                <td v-if="not_empty_row_def['icur']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icur_en' : 'icur'])}}
                                </td>
                                <td v-if="not_empty_row_def['icur_probability']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'icur_probability_en' : 'icur_probability'])}}
                                </td>
                                <td v-if="not_empty_row_def['parameter']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'parameter_en' : 'parameter'])}}
                                </td>
                                <td v-if="not_empty_row_def['results']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'results_en' : 'results'])}}
                                </td>
                                <td v-if="not_empty_row_def['smc_comment']">
                                    {{check_empty(sensitivity_analysis_def[$i18n.locale != 'fr' && $route.params.agency == 'ceesp' ? 'smc_comment_en' : 'smc_comment'])}}
                                </td>
                            </tr>  
                        </tbody>
                    </table> 
                </div><!--table-responsive--> 
            </div><!---box-toggle-->

            <div class="box-toggle" :key="'economic_comparator_head'+key" :id="'economic_comparator-'+key" v-if="items['comparator_active_comparator'] && items['comparator_active_comparator'] != 0 || items['evaluation_economic_comparator_name'].length > 0">        
                <a class="title title-tb" @click="economic_comparator_show[key] = !economic_comparator_show[key]" style="cursor:pointer;">
                    {{$t('economic_comparator_head')}}
                    <em :class="economic_comparator_show[key] ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                </a>

                <div v-if="economic_comparator_show[key]">
                    <table class="comparateur2 border-top-gray">
                        <tbody>
                            <tr style="text-align: center !important;">
                                <th width="36%" v-if="items['comparator_active_comparator'] && items['comparator_active_comparator'] != 0">{{$t(prefix_+'comparator_active_comparator')}}</th>
                                <th width="32%" v-if="items['evaluation_economic_comparator_name'].length > 0">{{$t(prefix_+'comparator_name')}}</th>
                                <th width="36%" v-if="($i18n.locale == 'fr' && items['comparator_comparator_reievance']) ||
                                        ($i18n.locale != 'fr' && items['comparator_comparator_reievance_en'])">
                                            {{$t(prefix_+'comparator_comparator_reievance')}}
                                </th>
                            </tr>                         
                            <tr>
                                <td v-if="items['comparator_active_comparator'] && items['comparator_active_comparator'] != 0">{{$t('evaluation_economic.yes_no_'+items['comparator_active_comparator'])}}</td>
                                <td v-if="items['evaluation_economic_comparator_name'].length > 0">
                                    <template v-for="(item_comparator, key_com) in items['evaluation_economic_comparator_name']" >
                                        <p :key="'evaluation_economic_comparator_name' +key_com" v-if="$i18n.locale == 'fr'|| $route.params.agency != 'ceesp'">{{item_comparator['comparator_name']}}</p>
                                        <p :key="'evaluation_economic_comparator_name' +key_com" v-else>{{item_comparator['comparator_name_en']}}</p>
                                    </template>
                                </td>
                                <td v-if="($i18n.locale == 'fr' && items['comparator_comparator_reievance']) ||
                                        ($i18n.locale != 'fr' && items['comparator_comparator_reievance_en'])">
                                    {{$i18n.locale == 'fr' ? items['comparator_comparator_reievance'] : items['comparator_comparator_reievance_en']}}
                                </td>
                            </tr>   
                        </tbody>         
                    </table> 
                </div> 
            </div><!---box-toggle-->  

            <hr style="border-color: #D14207; border-width: 2px;"  v-if="data.length > 0 && data.length != key+1">

            </div>

        
        </template>
    </div>    
</div>
</template>

<script>
import { check_empty } from '../../utils'
export default {
    name : "EvaluationEconomic",
    data(){
        return {
            economic_analysis_show : {},
            sensitivity_analysis_show : {
                'dsa' : false,
                'psa' : false,
                'sa' : false,
                'def' : false,
            },
            economic_comparator_show : {},
            not_empty_row : {
                'source_of_analysis' : false,
                'patient_population' : false,
                'comparator' : false,
                'icer_gbp' : false,
                'icer_range_gbp' : false,
                'icur_gbp' : false,
                'icur_range_gbp' : false,
                'cost_savings_gbp' : false,
                'total_cost_gbp' : false,
                'net_monetary_benefit_gbp' : false,
                'net_monetary_loss_gbp' : false,
                'smc_comment' : false
            },
            not_empty_row_dsa : {
                'source_of_analysis' : false,
                'patient_population' : false,
                'comparator' : false,
                'parameter' : false,
                'icer' : false,
                'icur' : false,
                'smc_comment' : false
            },
            not_empty_row_psa : {
                'source_of_analysis' : false,
                'patient_population' : false,
                'comparator' : false,
                'method' : false,
                'icer_probability' : false,
                'icur_probability' : false,
                'smc_comment' : false
            },
            not_empty_row_sa : {
                'source_of_analysis' : false,
                'patient_population' : false,
                'comparator' : false,
                'scenario' : false,
                'icur' : false,
                'smc_comment' : false
            },
            not_empty_row_def : {
                'source_of_analysis' : false,
                'patient_population' : false,
                'comparator' : false,
                'method' : false,
                'icer' : false,
                'icer_probability' : false,
                'icur' : false,
                'icur_probability' : false,
                'parameter' : false,
                'results' : false,
                'smc_comment' : false
            },
            prefix_ : '',
            prefix_comment : '',
            prefix_economic_analysis_head : '',
            prefix_flag : '',
            prefix_flag_g1 : '',
            prefix_icur_flag : '',
            prefix_icer : '',
            collapse_show : {}

        }
    },
    props : {
        data : {
            style : Array
        }
    },
    mounted() {
        for(let key in this.data){
            this.collapse_show[key] = 0
            for(let sub_key in this.data[key].evaluation_economic_economic_analysis){
                for(let key_2 in this.not_empty_row){
                    if(!this.not_empty_row[key_2]){
                        if(this.$i18n.locale != 'fr' && this.$route.params.agency == 'ceesp'){
                            this.not_empty_row[key_2] = this.data[key].evaluation_economic_economic_analysis[sub_key][key_2+'_en'] ? true : false
                        }else{
                            this.not_empty_row[key_2] = this.data[key].evaluation_economic_economic_analysis[sub_key][key_2] ? true : false
                        }
                    }
                    
                }
            }
        }
        for(let key in this.economic_sensitivity_analysis_dsa){
            for(let key_2 in this.not_empty_row_dsa){
                if(!this.not_empty_row_dsa[key_2]){
                    if(this.$i18n.locale != 'fr' && this.$route.params.agency == 'ceesp'){
                        this.not_empty_row_dsa[key_2] = this.economic_sensitivity_analysis_dsa[key][key_2+'_en'] ? true : false
                    }else{
                        this.not_empty_row_dsa[key_2] = this.economic_sensitivity_analysis_dsa[key][key_2] ? true : false
                    }
                }
            }
        }
        for(let key in this.economic_sensitivity_analysis_psa){
            for(let key_2 in this.not_empty_row_psa){
                if(!this.not_empty_row_psa[key_2]){
                    if(this.$i18n.locale != 'fr' && this.$route.params.agency == 'ceesp'){
                        this.not_empty_row_psa[key_2] = this.economic_sensitivity_analysis_psa[key][key_2+'_en'] ? true : false
                    }else{
                        this.not_empty_row_psa[key_2] = this.economic_sensitivity_analysis_psa[key][key_2] ? true : false
                    }
                }
            }
        }
        for(let key in this.economic_sensitivity_analysis_sa){
            for(let key_2 in this.not_empty_row_sa){
                if(!this.not_empty_row_sa[key_2]){
                    if(this.$i18n.locale != 'fr' && this.$route.params.agency == 'ceesp'){
                        this.not_empty_row_sa[key_2] = this.economic_sensitivity_analysis_sa[key][key_2+'_en'] ? true : false
                    }else{
                        this.not_empty_row_sa[key_2] = this.economic_sensitivity_analysis_sa[key][key_2] ? true : false
                    }
                }
            }
        }
        for(let key in this.economic_sensitivity_analysis_def){
            for(let key_2 in this.not_empty_row_def){
                if(!this.not_empty_row_def[key_2]){
                    if(this.$i18n.locale != 'fr' && this.$route.params.agency == 'ceesp'){
                        this.not_empty_row_def[key_2] = this.economic_sensitivity_analysis_def[key][key_2+'_en'] ? true : false
                    }else{
                        this.not_empty_row_def[key_2] = this.economic_sensitivity_analysis_def[key][key_2] ? true : false
                    }
                }
            }
        }
        switch(this.$route.params.agency){
            case'ceesp':
                this.prefix_ = 'ceesp_'
                this.prefix_comment = 'ceesp_'
                this.prefix_economic_analysis_head = 'ceesp_'
                break;
            case'smc':
                this.prefix_comment = 'SMC '
                this.prefix_flag = ' (GBP)'
                this.prefix_flag_g1 = ' (GBP/QALY gained)'
                break;
            case'nice':
                this.prefix_comment = 'NICE '
                this.prefix_flag = ' (GBP)'
                this.prefix_flag_g1 = ' (GBP/QALY gained)'
                break;
            case'cadth':
                this.prefix_comment = 'CADTH '
                this.prefix_flag = ' (CAD)'
                this.prefix_icur_flag = ' (CAD/QALY gained)'
                break;
            case'perc':
                this.prefix_comment = 'pCODR '
                this.prefix_flag = ' (CAD)'
                this.prefix_flag_g1 = ' (CAD/QALY gained)'
                break;
            case'pbac':
                this.prefix_comment = 'PBAC '
                this.prefix_flag = ' (AUD)'
                this.prefix_flag_g1 = ' (AUD/QALY gained)'
                this.prefix_icer = ' (AUD/QALY gained)'
                break;
            case'ptac':
                this.prefix_comment = 'PTAC '
                this.prefix_flag = ' (NZD)'
                break;
            case'tlv':
                this.prefix_comment = 'TLV '
                this.prefix_flag = ' (SEK)'
                break;
            case'bresil':
                this.prefix_comment = 'CONITEC '
                this.prefix_flag = ' (BRL)'
                break;
            case'italy':
                this.prefix_comment = 'Agency '
                this.prefix_flag = ' (EUR)'
                break;
            case'spain':
                this.prefix_comment = 'Agency '
                this.prefix_flag = ' (EUR)'
                break;
        }
    },
    methods : {
        check_empty,
        toggle_sensitivity_analysis(param){
            if(this.sensitivity_analysis_show[param]){
                this.sensitivity_analysis_show[param] = false
            }else{
                this.sensitivity_analysis_show[param] = true
            }
        },
        collapse_toggle(key){
            console.log(key, this.collapse_show[key])
            if(this.collapse_show[key] == 1){
                this.collapse_show[key] = 0
            }else{
                this.collapse_show[key] = 1
            }
            this.$forceUpdate();
        }
    },
    computed : {
        economic_sensitivity_analysis_dsa(){
            let list_type = []
            for(let key in this.data){
                for(let key2 in this.data[key].evaluation_economic_sensitivity_analysis){
                    if(this.data[key].evaluation_economic_sensitivity_analysis[key2].list_type == 'dsa'){
                        list_type.push(this.data[key].evaluation_economic_sensitivity_analysis[key2])
                    }
                    
                }
            }
            return list_type
        },
        economic_sensitivity_analysis_psa(){
            let list_type = []
            for(let key in this.data){
                for(let key2 in this.data[key].evaluation_economic_sensitivity_analysis){
                    if(this.data[key].evaluation_economic_sensitivity_analysis[key2].list_type == 'psa'){
                        list_type.push(this.data[key].evaluation_economic_sensitivity_analysis[key2])
                    }
                }
            }
            return list_type
        },
        economic_sensitivity_analysis_sa(){
            let list_type = []
            for(let key in this.data){
                for(let key2 in this.data[key].evaluation_economic_sensitivity_analysis){
                    if(this.data[key].evaluation_economic_sensitivity_analysis[key2].list_type == 'sa'){
                        list_type.push(this.data[key].evaluation_economic_sensitivity_analysis[key2])
                    }
                }
            }
            return list_type
        },
        economic_sensitivity_analysis_def(){
            let list_type = []
            for(let key in this.data){
                for(let key2 in this.data[key].evaluation_economic_sensitivity_analysis){
                    if(this.data[key].evaluation_economic_sensitivity_analysis[key2].list_type == 'def'){
                        list_type.push(this.data[key].evaluation_economic_sensitivity_analysis[key2])
                    }
                }
            }
            return list_type
        }
    }
    
}
</script>

<style scoped>


.fa-angle-up {
    color : #ff6300;
}
.box-toggle .icon-arrow-updown {
    font-size: 16px;
    right: -14px;
    color: #232426;
    margin-left:5px;
}
.box-toggle a:hover i {
    color: #ff6300;
}
table.comparateur2 tbody tr td {
    text-align: center;
}
p{
    margin :0;
}
.no-border-left{
    border-left : 0px !important; 
} 
.no-border-right{
    border-right : 0px !important;
} 
.no-border-bottom{
    border-bottom : 0px !important;
}
.no-border-top{
    border-top : 0px !important;
} 
.no-border-left-right{
    border-left : 0px !important;
    border-right : 0px !important;
}
</style>