<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->
                    <!--General information start-->
                    <div class="content-onglets table" v-if="key == 'detail_general_information' && item.show">
                        <table class="comparateur2 border-top-gray">
                            <tr v-if="data.usa.usa_detail.agency">
                                <th width="20%" class="text-left bg-white">Agency</th>
                                <td class="bg-white text-left"> {{ data.usa.usa_detail.agency.toUpperCase() }} </td>
                            </tr>
                            <tr v-if="data.usa.usa_detail.program">
                                <th width="20%" class="text-left bg-white">Program</th>
                                <td class="bg-white text-left"> {{ data.usa.usa_detail.program }} </td>
                            </tr>
                            <tr v-if="data.usa.usa_detail.date_advice">
                                <th width="20%" class="text-left bg-white">Final report date</th>
                                <td class="bg-white text-left"> {{ DDMMMYY(data.usa.usa_detail.date_advice) }} </td>
                            </tr>
                            <tr v-if="data.usa.usa_detail.topic">
                                <th width="20%" class="text-left bg-white">Topic</th>
                                <td class="bg-white text-left"> {{ data.usa.usa_detail.topic }} </td>
                            </tr>
                        </table>
                    </div>
                    <!--General information end-->

                    <!--New Drugs Under Review start-->
                    <div class="content-onglets" v-else-if="key == 'detail_new_drugs_under_review' && item.show" >
                        <p v-html="data.usa.usa_detail['new_drugs_under_review']"></p>
                    </div>
                    <!--New Drugs Under Review end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->

                    <!--Review history start--> 
                    <div class="content-onglets table" v-else-if="key == 'detail_UsaHistory' && item.show">
                        <table class="comparateur2"> 
                            <thead>
                                <tr>
                                    <th width="25%"> Revision date </th>
                                    <th width="75%"> Details </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(value, key) in data.usa.usa_detail['usa_history']" :key="'UsaHistory_'+key">
                                    <td> {{value['date'] ? DDMMMYY(value['date']) : '-'}} </td>
                                    <td> {{value['details'] ? value['details'] : '-'}} </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="space"></div>
                    </div>
                    <!--Review history end-->

                    <!--Policy start-->
                    <div class="content-onglets" v-else-if="key == 'detail_policy' && item.show">
                        <p v-html="data.usa.usa_detail['policy']"></p>
                    </div>
                    <!--Policy end-->

                     <!--background start-->
                    <div class="content-onglets" v-else-if="key == 'detail_background' && item.show">
                        <p v-html="data.usa.usa_detail['background']"></p>
                    </div>
                    <!--background end-->

                    <!--NCT number aetna start--> 
                    <div class="content-onglets " style="text-align: left" v-if="key == 'detail_NctNumber' && item.show">
                        <template v-for="(value, key) in data.usa.usa_detail['nct_numbers']">
                            <div v-if="value['text']" v-html="value['text']" :key="'detail_NctNumber_aetna_'+key" style="display: inline-block;padding: 10px;border: 1px solid #B1A7A1;margin-right: 13px;margin-bottom: 10px;"></div>
                        </template>
                    </div>
                    <!--NCT number aetna end-->

                    <!--Appendix start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_appendix' && item.show">
                        <p v-html="data.usa.usa_detail['appendix']"></p>
                    </div>
                    <!--Appendix end-->

                    <!--CPT Codes / HCPCS Codes / ICD-10 Codes start-->  
                    <div class="content-onglets" v-else-if="key == 'detail_cpt_hcpcs_icd_code' && item.show">
                        <p v-html="data.usa.usa_detail['cpt_hcpcs_icd_code']"></p>
                    </div>
                    <!--CPT Codes / HCPCS Codes / ICD-10 Codes end-->

                    <!--Scope of the review start-->  
                    <div class="content-onglets" v-else-if="key == 'detail_Scope_of_the_review' && item.show">
                        <div class="box-toggle" v-if="data.usa.usa_detail['report_aims']">
                            <a class="title title-tb" @click="report_aims = !report_aims">
                                {{$t('usa.report_aims')}} 
                                <em :class="report_aims? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>
                            <div v-if="report_aims">
                                <div class="edit-wysihtml5" v-html="data.usa.usa_detail['report_aims']"></div>
                            </div>
                        </div>
                        <div class="box-toggle" v-if="data.usa.usa_detail['populations']">
                            <a class="title title-tb"  @click="populations = !populations">
                                {{$t('usa.populations')}} 
                                <em :class="populations? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>

                            <div v-if="populations">
                                <div class="edit-wysihtml5" v-html="data.usa.usa_detail['populations']"></div>
                            </div>
                        </div>
                        <div class="box-toggle" v-if="data.usa.usa_detail['interventions']">
                            <a class="title title-tb" @click="interventions = !interventions">                           
                                {{$t('usa.interventions')}} 
                                <em :class="interventions? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>                       
                            <div v-if="interventions">
                                <div class="edit-wysihtml5" v-html="data.usa.usa_detail['interventions']"></div>
                            </div>
                        </div>
                        <div class="box-toggle" v-if="data.usa.usa_detail['comparators']">
                            <a class="title title-tb" @click="comparators = !comparators">                            
                                {{$t('usa.comparators')}} 
                                <em :class="comparators? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>                      
                            <div v-if="comparators" v-html="data.usa.usa_detail['comparators']"></div>
                        </div>
                    </div> 
                    <!--Scope of the review end-->

                    <!--Scope of the review start-->
                    <div class="content-onglets" v-else-if="key == 'detail_in_summary' && item.show">
                        <div class="box-toggle" v-if="data.usa.usa_detail['in_summary']">
                            <a class="title title-tb" @click="in_summary = !in_summary">
                                {{$t('usa.in_summary')}} 
                                <em :class="in_summary? 'fa fa-angle-up icon-arrow-updown' :'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>
                        </div>
                        <div v-if="in_summary">
                            <div class="edit-wysihtml5" v-html="data.usa.usa_detail['in_summary']"></div>
                        </div>
                        <div class="box-toggle" v-if="data.usa.usa_detail['clinical_effectiveness']">
                            <a class="title title-tb" @click="clinical_effectiveness = !clinical_effectiveness">
                                {{$t('usa.clinical_effectiveness')}} 
                                <em :class="clinical_effectiveness? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>

                            <div v-if="clinical_effectiveness" v-html="data.usa.usa_detail['clinical_effectiveness']"></div>
                        </div>
                        <div class="box-toggle" v-if="data.usa.usa_detail['comparative_value']">
                            <a class="title title-tb" @click="comparative_value = !comparative_value">
                                {{$t('usa.comparative_value')}} 
                                <em :class="comparative_value? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>
                            <div v-if="comparative_value" v-html="data.usa.usa_detail['comparative_value']"></div>  
                        </div>
                    </div>
                    <!--Scope of the review end-->

                    <!--Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                    <!--Conclusion end-->

                    <!--Key Policy Implications and Recommendations start--> 
                    <div class="content-onglets table" v-else-if="key == 'detail_UsaKeyPolicy' && item.show">
                        <template v-if="data.usa.usa_detail['usa_key_policy'].length > 0">
                            <div class="box-toggle" :key="'UsaKeyPolicy'+key" v-for="(value, key) in data.usa.usa_detail['usa_key_policy']">
                                <a class="title title-tb" @click="UsaKeyPolicy_toggle(key)">
                                    {{ value['title'] }}  
                                    <em :class="UsaKeyPolicy[key] == 1 ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>

                                <div v-if="UsaKeyPolicy[key] == 1" v-html="value['key_policy']"></div> 
                            </div>
                        </template>   
                    </div>
                    <!--Key Policy Implications and Recommendations end-->

                    <!--NCT number icer start--> 
                    <div class="content-onglets" style="text-align: left" v-if="key == 'detail_NctNumber' && item.show">
                        <template v-for="(value, key) in data.usa.usa_detail['nct_numbers']">
                            <div v-if="value['text']" v-html="value['text']" style="display: inline-block;padding: 10px;border: 1px solid #B1A7A1;margin-right: 13px;margin-bottom: 10px;" :key="'detail_NctNumber_icer'+key"></div>
                       </template>
                    </div>
                    <!--NCT number icer end-->

                    <!-- detail_UsaClinicalEvidence start -->
                    <div class="content-onglets table" v-else-if="key == 'detail_UsaClinicalEvidence' && item.show">
                        <template v-if="data.usa.usa_detail['usa_clinical_evidence'].length > 0">

                            <div class="box-toggle" v-for="(value, key) in data.usa.usa_detail['usa_clinical_evidence']" :key="'UsaClinicalEvidence'+key">
                                <a class="title title-tb" @click="UsaClinicalEvidence_toggle(key)">
                                    {{ value['title'] }} 
                                    <em :class="UsaClinicalEvidence[key] == 1 ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>

                                <div v-if="UsaClinicalEvidence[key] == 1" v-html="value['clinical_evidence']"></div>
                            </div>
                            <div class="box-toggle" v-if="data.usa.usa_detail['harms']">
                                <a class="title title-tb" @click="harms = !harms">
                                    {{$t('usa.harms')}} 
                                    <em :class="harms? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em></a>

                                <div v-if="harms" v-html="data.usa.usa_detail['harms']"></div>
                            </div>
                            <div class="box-toggle" v-if="data.usa.usa_detail['source_uncertainty']">
                                <a class="title title-tb" @click="source_uncertainty = !source_uncertainty">
                                   {{$t('usa.source_uncertainty')}} 
                                   <em :class="source_uncertainty? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>                         
                                </a>

                                <div class="edit-wysihtml5" v-if="source_uncertainty" v-html="data.usa.usa_detail['source_uncertainty']"></div>   
                            </div>

                            <div class="box-toggle" v-if="data.usa.usa_detail['icer_evidence']">
                                <a class="title title-tb" @click="icer_evidence = !icer_evidence">
                                    {{$t('usa.icer_evidence')}} 
                                    <em :class="icer_evidence ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <div v-if="icer_evidence" v-html="data.usa.usa_detail['icer_evidence']"></div>
                            </div>
                        </template>
                    </div> 
                    <!-- detail_UsaClinicalEvidence end -->
                    
                    <!-- detail_economic_assessment start -->
                    <div class="content-onglets" v-else-if="key == 'detail_economic_assessment' && item.show">
                        <p v-if="data.usa.usa_detail['economic_assessment']" v-html="data.usa.usa_detail['economic_assessment']"></p>

                        <div class="box-toggle" v-if="data.usa.usa_detail['cost_effectiveness']">
                            <a class="title title-tb" @click="cost_effectiveness = !cost_effectiveness">
                                {{$t('usa.cost_effectiveness')}} 
                                <em :class="cost_effectiveness? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>                                              
                            <div v-if="cost_effectiveness" v-html="data.usa.usa_detail['cost_effectiveness']"></div>
                        </div>

                        <div class="box-toggle" v-if="data.usa.usa_detail['price_benchmark']">  
                            <a class="title title-tb" @click="price_benchmark = !price_benchmark">                           
                                {{$t('usa.price_benchmark')}} 
                                <em :class="price_benchmark ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em> 
                            </a>                        
                            <div v-if="price_benchmark" v-html="data.usa.usa_detail['price_benchmark']"></div>
                        </div>
                    </div> 
                    <!-- detail_economic_assessment end -->

                    <!-- detail_EvaluationEconomic  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.usa.usa_detail.evaluation_economic" />

                    <!-- detail_EconomicEvaluation -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                    <!--BudgetImpact start-->
                    <BudgetImpact :data="data.usa.usa_detail['budget_impacts']" v-else-if="key == 'detail_BudgetImpact' && item.show" /> 
                    <!--BudgetImpact end-->

                    <!-- detail_budget_impact start -->
                    <div class="content-onglets" v-else-if="key == 'detail_budget_impact' && item.show">
                        <p v-html="data.usa.usa_detail['budget_impact']"></p>
                    </div>
                    <!-- detail_budget_impact end -->

                    <!-- detail_other_considerations start -->
                    <div class="content-onglets" v-else-if="key == 'detail_other_considerations' && item.show">
                        <p v-html="data.usa.usa_detail['other_considerations']"></p>
                    </div>
                    <!-- detail_other_considerations end -->

                    <!--key_documents start-->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!--key_documents end-->  

                </div>
            </template>
          
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
			
			
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import Conclusion from '../conclusion.vue'
import BudgetImpact from '../budget_impact.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'

export default{
    name: 'usa',
    components : {
        LeftSection,
        TreatmentLine,
        EconomicEvaluation,
        EvaluationEconomic,
        Conclusion,
        BudgetImpact,
        LinkAgency,
        KeyDocument
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_general_information : {'title' : 'general_information', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_new_drugs_under_review : { 'title' : 'Drugs Under Review', 'show' : true, 'enable' : false, 'orange_text':false},               
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_UsaHistory : { 'title' : 'Review history', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_policy : { 'title' : 'Policy', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_background : {'title' : 'background', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_appendix : {'title' : 'Appendix', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_cpt_hcpcs_icd_code : {'title' : 'CPT Codes / HCPCS Codes / ICD-10 Codes', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_Scope_of_the_review : {'title' : 'Scope of the review', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_in_summary : {'title' : 'Conclusion', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_UsaKeyPolicy : {'title' : 'Key Policy Implications and Recommendations', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_UsaClinicalEvidence : {'title' : 'Clinical Evidence', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_economic_assessment : {'title' : 'Economic Assessment', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },           
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true }, 
                detail_BudgetImpact : {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':true },                
                detail_budget_impact : {'title' : 'usa.budget_impact', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_other_considerations : {'title' : 'Other considerations', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },             
            },
            report_aims : true,
            populations : true,
            interventions : true,
            comparators : true,
            in_summary : true, 
            clinical_effectiveness : true,
            comparative_value : true,
            UsaKeyPolicy : {},
            UsaClinicalEvidence : {},
            harms : true,
            source_uncertainty : true,
            icer_evidence : true,
            cost_effectiveness : true,
            price_benchmark : true,
            
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.usa && this.data.usa.usa_detail){
            
            if(this.data.usa.usa_detail.agency || this.data.usa.usa_detail.program || 
                this.data.usa.usa_detail.date_advice || this.data.usa.usa_detail.topic){
                this.list_check_box['detail_general_information'].enable = true
            } 
            if(this.data.usa.usa_detail['new_drugs_under_review'] && this.data.usa.usa_detail['agency'] == 'icer')
            { 
                this.list_check_box['detail_new_drugs_under_review'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.data.usa.usa_detail['usa_history'].length > 0 && this.data.usa.usa_detail['agency'] == 'aetna')
            { 
                this.list_check_box['detail_UsaHistory'].enable = true 
            }
            if(this.data.usa.usa_detail['policy'] && this.data.usa.usa_detail['agency'] == 'aetna')
            { 
                this.list_check_box['detail_policy'].enable = true 
            }
            if(this.data.usa.usa_detail['background'] && this.data.usa.usa_detail['agency'] == 'aetna')
            { 
                this.list_check_box['detail_background'].enable = true 
            }
            if(this.data.usa.usa_detail['appendix'] && this.data.usa.usa_detail['agency'] == 'aetna')
            { 
                this.list_check_box['detail_appendix'].enable = true 
            }
            if(this.data.usa.usa_detail['cpt_hcpcs_icd_code'] && this.data.usa.usa_detail['agency'] == 'aetna')
            { 
                this.list_check_box['detail_cpt_hcpcs_icd_code'].enable = true 
            }
            if((this.data.usa.usa_detail['report_aims'] || this.data.usa.usa_detail['populations'] ||
                this.data.usa.usa_detail['interventions'] || this.data.usa.usa_detail['comparators']) && 
                this.data.usa.usa_detail['agency'] == 'icer')
            { 
                this.list_check_box['detail_Scope_of_the_review'].enable = true 
            }
            if((this.data.usa.usa_detail['in_summary'] || this.data.usa.usa_detail['clinical_effectiveness'] ||
                this.data.usa.usa_detail['comparative_value']) && 
                this.data.usa.usa_detail['agency'] == 'icer')
            { 
                this.list_check_box['detail_in_summary'].enable = true 
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) 
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.usa.usa_detail['usa_key_policy'].length > 0 && this.data.usa.usa_detail['agency'] == 'icer')
            { 
                for(let key in this.data.usa.usa_detail['usa_key_policy']){
                    this.UsaKeyPolicy[key] = 1
                }
                this.list_check_box['detail_UsaKeyPolicy'].enable = true 
            } 
            if((this.data.usa.usa_detail['harms'] || this.data.usa.usa_detail['source_uncertainty'] ||
                this.data.usa.usa_detail['icer_evidence'] || this.data.usa.usa_detail['usa_clinical_evidence'].length > 0) &&
                this.data.usa.usa_detail['agency'] == 'icer')
            { 
                for(let key in this.data.usa.usa_detail['usa_clinical_evidence']){
                    this.UsaClinicalEvidence[key] = 1
                }
                this.list_check_box['detail_UsaClinicalEvidence'].enable = true 
            } 
            if((this.data.usa.usa_detail['economic_assessment'] || this.data.usa.usa_detail['cost_effectiveness'] ||
                this.data.usa.usa_detail['price_benchmark']) &&
                this.data.usa.usa_detail['agency'] == 'icer')
            { 
                this.list_check_box['detail_economic_assessment'].enable = true 
            } 

            if(this.data.usa.usa_detail['evaluation_economic'].length > 0 && this.data.usa.usa_detail['agency'] == 'icer')
            { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
            } 
            if(this.data.usa.usa_detail['budget_impacts'].length > 0 && this.data.usa.usa_detail['agency'] == 'icer')
            { 
                this.list_check_box['detail_BudgetImpact'].enable = true
            }
            if(this.data.usa.usa_detail['budget_impact'] && this.data.usa.usa_detail['agency'] == 'icer')
            { 
                this.list_check_box['detail_budget_impact'].enable = true
            }

            if(this.data.usa.usa_detail['other_considerations'] && this.data.usa.usa_detail['agency'] == 'icer')
            { 
                this.list_check_box['detail_other_considerations'].enable = true 
            } 

            // if(this.data.usa.usa_detail['key_document'] && this.data.usa.usa_detail['agency'] == 'icer')
            // { 
            //     this.list_check_box['detail_key_documents'].enable = true
            // }
            if(this.data.usa.usa_detail['agency'] == 'icer' && key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }


        }
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents,
        UsaKeyPolicy_toggle(key){
            if(this.UsaKeyPolicy[key] == 0){
                this.UsaKeyPolicy[key] = 1
            }else{
                this.UsaKeyPolicy[key] = 0
            }
            this.$forceUpdate();
        },
        UsaClinicalEvidence_toggle(key){
            if(this.UsaClinicalEvidence[key] == 0){
                this.UsaClinicalEvidence[key] = 1
            }else{
                this.UsaClinicalEvidence[key] = 0
            }
            this.$forceUpdate();
        },
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }        
    },
}

</script>

<style scoped>

a{
    cursor: pointer;
}
.fa-angle-up {
    color : #ff6300;
}
.box-toggle a:hover i {
    color: #ff6300;
}
section {
    margin-top : 10px;
    margin-bottom : 10px;
}
</style>