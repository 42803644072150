<template>

<div class="content-onglets table">          
        
    <p v-if="$route.params.agency == 'smc' && smc_add_bi"><span v-html="smc_add_bi"></span> </p><br> 

    <template v-for="(value, key) in data">    
        <p v-if="value['perspective']" :key="'perspective_'+key">
            <strong class="text-black"> Perspective : </strong> {{value['perspective']}} 
        </p>
        <p v-if="value['patient_population']" :key="'patient_population_'+key">
            <strong class="text-black"> Patient population : </strong>  {{value['patient_population']}} 
        </p>
        <p v-if="value['scenario']" :key="'scenario_'+key">
            <strong class="text-black"> Scenario :</strong> {{value['scenario']}}
        </p>
        
        <p v-if="txt_total_cost(value['budget_impact_detail_total_cost_patient'])" :key="'txt_total_cost_'+key">
            <strong class="text-black"> Total cost per patient :</strong> <span v-html="txt_total_cost(value['budget_impact_detail_total_cost_patient'])"></span>
        </p>                  
            
        <table class="comparateur2 table  tb-border" v-if="chk[key]['count'] != 0" :key="'table_'+key">
            <thead>
                <tr>                                
                    <th v-if="chk[key]['time_period']"> Time horizon </th>
                    <th v-if="chk[key]['budget_impact_detail_total_cost']"> Total cost </th>
                    <th v-if="chk[key]['number_treated']"> Number treated </th>
                    <th v-if="chk[key]['number_eligible_patients']"> Number of eligible patients </th>
                    <th v-if="chk[key]['uptake']"> Uptake </th>
                    <th v-if="chk[key]['market_share']"> Market share </th>
                    <th v-if="chk[key]['discontinuation_rate']"> Discontinuation rate </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(value_BudgetImpactDetail, key_BudgetImpactDetail) in value['budget_impact_detail']" :key="'key_BudgetImpactDetail'+key_BudgetImpactDetail">

                    <td v-if="chk[key]['time_period']"> 
                        {{value_BudgetImpactDetail['time_period'] ? value_BudgetImpactDetail['time_period'] : '-'}} </td>
                    <td v-if="chk[key]['budget_impact_detail_total_cost']"> 
                        <span v-html="txt_total_cost(value_BudgetImpactDetail['budget_impact_detail_total_cost']) ? txt_total_cost(value_BudgetImpactDetail['budget_impact_detail_total_cost'], 0, -4) : '-'"></span> 
                    </td>
                    <td v-if="chk[key]['number_treated']"> 
                        {{value_BudgetImpactDetail['number_treated'] ? value_BudgetImpactDetail['number_treated'] : '-'}} 
                    </td>
                    <td v-if="chk[key]['number_eligible_patients']"> 
                        {{value_BudgetImpactDetail['number_eligible_patients'] ? value_BudgetImpactDetail['number_eligible_patients'] : '-'}} 
                    </td>
                    <td v-if="chk[key]['uptake']"> 
                        {{value_BudgetImpactDetail['uptake'] ? value_BudgetImpactDetail['uptake'] : '-'}} 
                    </td>
                    <td v-if="chk[key]['market_share']"> 
                        {{value_BudgetImpactDetail['market_share'] ? value_BudgetImpactDetail['market_share'] : '-'}} 
                    </td>
                    <td v-if="chk[key]['discontinuation_rate']">  
                        {{value_BudgetImpactDetail['discontinuation_rate'] ? value_BudgetImpactDetail['discontinuation_rate'] : '-'}} 
                    </td>
                </tr>
            </tbody>   
        </table>
            
        <p v-if="value['comments']" :key="'comments_'+key">
            <strong class="text-black"> Comments  :</strong> {{value['comments']}}
        </p>
        
        <hr style="border-color: #f7941d;border-width: 1px;" v-if="data.length > 0 && data.length != key+1" :key="'hr'+key">

    </template>    
</div>


</template>

<script>
export default {
    name : 'BudgetImpact',
    props : {
        data : {
            style : Array
        },
        smc_add_bi : {
            style : String,
            default : ''
        }
    },
    computed : {
        fixtxt(){
            let fixtxt = ''
            switch (this.$route.params.agency) {
                case "spain":
                    fixtxt = "(€)";
                    break;
                case "tlv":
                    fixtxt = "(kr)";
                    break;
                case "usa":
                    fixtxt = "($)";
                    break;
            }
             return fixtxt
        },
        chk(){
            let chk = {}
            for (let key in this.data ) {
                chk[key] = {}
                chk[key]['time_period'] = false
                chk[key]['budget_impact_detail_total_cost'] = false
                chk[key]['budget_impact_detail_total_cost_patient'] = false
                chk[key]['number_eligible_patients'] = false
                chk[key]['number_treated'] = false
                chk[key]['uptake'] = false
                chk[key]['discontinuation_rate'] = false
                chk[key]['market_share'] = false
                chk[key]['count'] = 0
                chk[key]['column'] = 0
                chk[key]['column_width'] = 1

                let value = this.data[key]

                if (value['budget_impact_detail_total_cost_patient']) {
                    for(let key1 in value['budget_impact_detail_total_cost_patient']){
                        if(value['budget_impact_detail_total_cost_patient'][key1]['total_cost']){
                            chk[key]['budget_impact_detail_total_cost_patient'] = true
                            chk[key]['count'] ++
                        }
                    }
                }

                if (value['budget_impact_detail']) {
                    for(let key2 in value['budget_impact_detail']) {
                        let value_BudgetImpactDetail = value['budget_impact_detail'][key2]
                        if (value_BudgetImpactDetail['time_period']) {
                            chk[key]['time_period'] = true;
                            chk[key]['count'] ++;
                        }
                        if (value_BudgetImpactDetail['number_eligible_patients'][key]) {
                            chk[key]['number_eligible_patients'] = true;
                            chk[key]['count'] ++;
                        }
                        if (value_BudgetImpactDetail['number_treated']) {
                            chk[key]['number_treated'] = true;
                            chk[key]['count'] ++;
                        }
                        if (value_BudgetImpactDetail['uptake']) {
                            chk[key]['uptake'] = true;
                            chk[key]['count'] ++;
                        }
                        if (value_BudgetImpactDetail['market_share']) {
                            chk[key]['market_share'] = true;
                            chk[key]['count'] ++;
                        }
                        if (value_BudgetImpactDetail['discontinuation_rate']) {
                            chk[key]['discontinuation_rate'] = true;
                            chk[key]['count'] ++;
                        }

                        if (value_BudgetImpactDetail['budget_impact_detail_total_cost']) {
                            for( let key3 in value_BudgetImpactDetail['budget_impact_detail_total_cost']) {
                                let value_BudgetImpactDetailTotalCost = value_BudgetImpactDetail['budget_impact_detail_total_cost'][key3]
                                if (value_BudgetImpactDetailTotalCost['total_cost']) {
                                    chk[key]['budget_impact_detail_total_cost'] = true;
                                    chk[key]['count'] ++;
                                }
                            }
                        }
                    }
                }
            }
        return chk
        }
    },
    methods : {
        txt_total_cost(value){
            if (value) { 
                let txt_total_cost = ""
                for(let key in value){
                    if(value['average_annual_cost'] && value['average_annual_cost'] === 1){
                        txt_total_cost += "Average annual cost : "
                    }
                    txt_total_cost += value[key]['total_cost'] ? value[key]['total_cost'] : ""
                    txt_total_cost += value[key]['gross'] == 1 ? " - Gross" : ""
                    txt_total_cost += value[key]['net'] == 1 ? " - Net" : ""
                    if(txt_total_cost != ""){
                        txt_total_cost += '</br>'
                    }
                }
                return txt_total_cost
            }
        }
    }
}
</script>

<style>

</style> 